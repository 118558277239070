.basic-integrations .ant-card p {
  font-size: 12pt;
  line-height: 13pt;
}
.basic-integrations .ant-card.card-grandhood.card-grandhood, .basic-integrations .ant-card.card-advisor.card-grandhood {
  background-image: url("/src/assets/images/grandhood-corner-logo.png");
  background-repeat: no-repeat;
  background-position: bottom 25px right 30px;
  background-size: auto 45px;
}
.basic-integrations .ant-card.card-grandhood.card-grandhood .button-row button, .basic-integrations .ant-card.card-advisor.card-grandhood .button-row button {
  margin: 0;
}
.basic-integrations .ant-card.card-grandhood.card-grandhood > .ant-card-body > div, .basic-integrations .ant-card.card-advisor.card-grandhood > .ant-card-body > div {
  background-size: auto 475px;
}
.basic-integrations .ant-card.card-grandhood.card-grandhood > .ant-card-body > div.integration-in-use, .basic-integrations .ant-card.card-advisor.card-grandhood > .ant-card-body > div.integration-in-use {
  background-image: url("/src/assets/images/grandhood-intergration-in-use.png");
}
.basic-integrations .ant-card.card-grandhood.card-grandhood > .ant-card-body > div.integration-need-signature, .basic-integrations .ant-card.card-advisor.card-grandhood > .ant-card-body > div.integration-need-signature {
  background-image: url("/src/assets/images/grandhood-need-signature.png");
}
.basic-integrations .ant-card.card-grandhood.card-grandhood > .ant-card-body > div.integration-not-started, .basic-integrations .ant-card.card-advisor.card-grandhood > .ant-card-body > div.integration-not-started {
  background-image: url("/src/assets/images/grandhood-not-started.png");
}
.basic-integrations .ant-card.card-grandhood.card-grandhood > .ant-card-body > div blockquote, .basic-integrations .ant-card.card-advisor.card-grandhood > .ant-card-body > div blockquote {
  background-color: #FFE5F2;
  top: 200px;
  right: 75px;
}
.basic-integrations .ant-card.card-grandhood.card-advisor > .ant-card-body > div, .basic-integrations .ant-card.card-advisor.card-advisor > .ant-card-body > div {
  background-size: auto 700px;
  background-image: url("/src/assets/images/ageras-integration.png");
}
.basic-integrations .ant-card.card-grandhood.card-advisor blockquote, .basic-integrations .ant-card.card-advisor.card-advisor blockquote {
  background-color: #042D52;
  top: 100px;
  right: 125px;
}
.basic-integrations .ant-card.card-grandhood.card-advisor blockquote p, .basic-integrations .ant-card.card-grandhood.card-advisor blockquote cite, .basic-integrations .ant-card.card-advisor.card-advisor blockquote p, .basic-integrations .ant-card.card-advisor.card-advisor blockquote cite {
  color: #fff;
}
.basic-integrations .ant-card.card-grandhood.card-advisor h3 ~ p, .basic-integrations .ant-card.card-advisor.card-advisor h3 ~ p {
  margin-top: 15px;
}
.basic-integrations .ant-card.card-grandhood.card-advisor h2, .basic-integrations .ant-card.card-advisor.card-advisor h2 {
  margin-top: 15px;
}
.basic-integrations .ant-card.card-grandhood.card-advisor h2 ~ ol, .basic-integrations .ant-card.card-advisor.card-advisor h2 ~ ol {
  margin-top: 10px;
}
.basic-integrations .ant-card.card-grandhood.card-advisor ol h3, .basic-integrations .ant-card.card-advisor.card-advisor ol h3 {
  margin-bottom: 0;
}
.basic-integrations .ant-card.card-grandhood.card-advisor ol li ~ li, .basic-integrations .ant-card.card-advisor.card-advisor ol li ~ li {
  margin-top: 5px;
}
.basic-integrations .ant-card.card-grandhood h1, .basic-integrations .ant-card.card-advisor h1 {
  font-size: 35pt;
  line-height: 35pt;
  margin-bottom: 25px;
}
.basic-integrations .ant-card.card-grandhood > .ant-card-body > div, .basic-integrations .ant-card.card-advisor > .ant-card-body > div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-repeat: no-repeat;
  background-position: top right;
  min-height: 520px;
}
.basic-integrations .ant-card.card-grandhood > .ant-card-body > div p, .basic-integrations .ant-card.card-advisor > .ant-card-body > div p {
  max-width: 450px;
  line-height: 18pt;
}
.basic-integrations .ant-card.card-grandhood > .ant-card-body > div a, .basic-integrations .ant-card.card-advisor > .ant-card-body > div a {
  color: #000000;
  text-decoration: underline;
}
.basic-integrations .ant-card.card-grandhood > .ant-card-body > div ul, .basic-integrations .ant-card.card-advisor > .ant-card-body > div ul {
  margin-top: 25px;
}
.basic-integrations .ant-card.card-grandhood > .ant-card-body > div ul li::before, .basic-integrations .ant-card.card-advisor > .ant-card-body > div ul li::before {
  content: "";
  display: inline-block;
  height: 11px;
  width: 15px;
  background-image: url("/src/assets/images/grandhood-tick.png");
  background-size: 100% 100%;
  margin-right: 5px;
}
.basic-integrations .ant-card.card-grandhood > .ant-card-body > div ul li:not(:first-of-type), .basic-integrations .ant-card.card-advisor > .ant-card-body > div ul li:not(:first-of-type) {
  margin-top: 5px;
}
.basic-integrations .ant-card.card-grandhood > .ant-card-body > div ol, .basic-integrations .ant-card.card-advisor > .ant-card-body > div ol {
  width: 600px;
}
.basic-integrations .ant-card.card-grandhood > .ant-card-body > div blockquote, .basic-integrations .ant-card.card-advisor > .ant-card-body > div blockquote {
  padding: 20px;
  position: relative;
}
.basic-integrations .ant-card.card-grandhood > .ant-card-body > div blockquote p, .basic-integrations .ant-card.card-advisor > .ant-card-body > div blockquote p {
  max-width: revert;
  font-size: 14pt;
  line-height: 20pt;
  font-style: italic;
}
.basic-integrations .ant-card.card-grandhood > .ant-card-body > div blockquote cite, .basic-integrations .ant-card.card-advisor > .ant-card-body > div blockquote cite {
  font-style: normal;
  line-height: 10pt;
}
.basic-integrations .ant-card.card-grandhood > .ant-card-body > div blockquote cite strong, .basic-integrations .ant-card.card-advisor > .ant-card-body > div blockquote cite strong {
  color: inherit;
  font-weight: 800;
}
.basic-integrations .ant-card.card-rmindr ol {
  list-style: circle;
  color: var(--sally-grey-shaded);
  font-size: 12pt;
  margin-left: 15px;
  margin-bottom: 15px;
}
.basic-integrations .ant-card.card-rmindr code {
  font-weight: 800;
  color: var(--sally-green-shaded);
}

