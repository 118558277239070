.company-accounting-integration {
    max-width: 1240px;
        margin: auto;
    .company-accounting-integration-overview {
        .integration-search, .connection-search {
            margin: 30px 0 0 0;
            padding-right: 118px;
            padding-left: 120px;
            background-repeat: no-repeat;

            label {
                display: block;
                margin-bottom: 5px;
            }
            + * {
                margin-top: 30px;
            }
            &.integration-search {
                background-position: 41px center;
                background-size: 45px 45px;
            }
            &.connection-search {
                background-position: 43px center;
                background-size: 40px 45px;
            }
        }
        .ant-input-group.ant-input-group-compact {
            .ant-input {
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;

                & + .ant-input {
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                }
            }
            .ant-select {
                & > .ant-select-selection {
                    border-top-left-radius: 4px;
                    border-bottom-left-radius: 4px;
                }
                & + .ant-input {
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                }
            }
        }
    }
}

.title-and-back-btn {
    display: flex;
    align-content:center;
    justify-content: space-between;
}