.company-dashboards {
  max-width: 1840px;
  margin: auto;
}
.company-dashboards .title-menu a + *, .company-dashboards .title-menu span + *, .company-dashboards .title-menu .ant-btn + * {
  margin-left: 20px;
}
.company-dashboards .title-menu .ant-input-search.ant-input-affix-wrapper {
  width: inherit;
}
.company-dashboards .title-menu .ant-input-search.ant-input-affix-wrapper .ant-input {
  width: 200px;
}
.company-dashboards .title-menu a + a .ant-btn {
  padding-left: 20px;
  font-size: 14px;
}
.company-dashboards .ant-table .ant-table-thead > tr > th, .company-dashboards .ant-table .ant-table-tbody > tr > td {
  padding-right: 10px;
  padding-left: 10px;
}
.company-dashboards .ant-table .ant-table-thead > tr > th:first-child, .company-dashboards .ant-table .ant-table-tbody > tr > td:first-child {
  padding-left: 30px;
}
.company-dashboards .ant-table .ant-table-thead > tr > th:last-child, .company-dashboards .ant-table .ant-table-tbody > tr > td:last-child {
  padding-right: 30px;
}
.company-dashboards .ant-table .ant-table-row td > div {
  display: block;
  line-height: 26px;
  font-weight: 400;
}
.company-dashboards .ant-table .ant-table-row td > div a {
  color: var(--sally-link);
}
.company-dashboards .ant-table .ant-table-row td > div strong {
  font-weight: 600;
}
.company-dashboards .ant-table .ant-table-row td.company-dashboards-info {
  width: 20%;
}
.company-dashboards .dashboard-calendar-inner {
  border-top: none !important;
}
.company-dashboards .dashboard-calendar-inner .dashboard-calendar-row > *.dashboard-calendar-col1 {
  width: 55% !important;
}
.company-dashboards .dashboard-calendar-inner .dashboard-calendar-row > *.dashboard-calendar-col2 {
  width: 22% !important;
}
.company-dashboards .dashboard-calendar-inner .dashboard-calendar-row > *.dashboard-calendar-col3 {
  width: 23% !important;
}

