.ant-switch {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  height: 20px;
  min-width: 36px;
  line-height: 20px;
  vertical-align: middle;
  border-radius: 20px;
  border: 1px solid transparent;
  background-color: var(--sally-switch-background);
  cursor: pointer;
  transition: all 0.36s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-right: 10px;
}
.ant-switch .ant-switch-inner {
  color: var(--sally-switch-nubbin);
  font-size: 12px;
  margin-left: 24px;
  margin-right: 6px;
  display: block;
}
.ant-switch:after {
  position: absolute;
  width: 16px;
  height: 16px;
  left: 2px;
  top: 1.5px;
  border-radius: 18px;
  background-color: var(--sally-switch-nubbin);
  content: " ";
  cursor: pointer;
  transition: all 0.36s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  box-shadow: 0 0 2px 0 var(--sally-switch-nubbin-shadow);
}
.ant-switch:active:after {
  width: 24px;
}
.ant-switch:hover, .ant-switch:active {
  background-color: var(--sally-switch-hover-background);
}
.ant-switch.ant-switch-checked {
  background-color: var(--sally-toggle-checked-background);
}
.ant-switch.ant-switch-checked .ant-switch-inner {
  margin-left: 6px;
  margin-right: 24px;
}
.ant-switch.ant-switch-checked:after {
  left: 100%;
  margin-left: -19px;
  box-shadow: 0 0 2px 0 var(--sally-switch-checked-nubbin-shadow);
}
.ant-switch.ant-switch-checked:active:after {
  margin-left: -25px;
}
.ant-switch.ant-switch-checked:hover {
  background-color: var(--sally-toggle-checked-hover-background);
}
.ant-switch.ant-switch-disabled {
  cursor: not-allowed;
  background-color: var(--sally-switch-disabled-background);
}
.ant-switch.ant-switch-disabled:after {
  background: var(--sally-switch-nubbin);
  cursor: not-allowed;
}
* + .ant-switch {
  margin-left: 10px;
}

.ant-switch-wrapper {
  margin-bottom: 0.5em;
}

