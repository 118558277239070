.time-registration {

    .ant-card{
        margin-top:  2em;
    }
    .h1 {
        margin-bottom: 10px;

        .ant-select {
            width: 200px;
            margin-left: 20px;
        }

        &+p {
            font-size: 14px;
        }
    }

    .h3 {
        line-height: inherit;

        >.user-image {
            float: left;
            margin: 3px 18px 0 0;
        }
    }

    .ant-table .ant-table-row {
        cursor: pointer;

        td>a {
            display: inline-block;
            padding: 5px 0;
            color: var(--sally-orange);
        }

        .time-registration-hour-line {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: 200px;
        }

        .time-registration-cell-loading {
            font-style: italic;
        }
    }

    .gtm-import-timereg {
        margin-bottom: 1.5em;
    }

    .gtm-toggle-time-view {
        margin-bottom: 1.5em;
        width: 147.05 px;
    }
}

.time-registration-import {

    a,
    .ant-btn {
        &+.ant-btn {
            margin-left: 10px;
            padding-left: 20px;
            font-size: 14px;
        }
    }

    .ant-upload.ant-upload-drag {
        display: inline-block;
        width: inherit;
    }

    .preview-table {
        font-size: 14px;

        .ant-row {
            margin-bottom: 10px;

            &>div+div {
                text-align: right;
            }
        }
    }
}