body .leave-registration .ant-card {
  margin-top: 2em;
}
body .leave-registration .h1 {
  margin-bottom: 10px;
}
body .leave-registration .h1 + p {
  font-size: 14px;
}
body .leave-registration .h3 {
  line-height: inherit;
}
body .leave-registration .h3 > .user-image {
  float: left;
  margin: 3px 18px 10px 0;
}
body .leave-registration .ant-table-wrapper .ant-table .ant-table-body .ant-table-row {
  cursor: pointer;
}
body .leave-registration .ant-table-wrapper .ant-table .ant-table-body .ant-table-row td > div > a {
  white-space: nowrap;
}
body .leave-registration .ant-table-wrapper .ant-table .ant-table-body tr > th {
  white-space: nowrap;
}
body .leave-registration .ant-table-wrapper .ant-table .ant-table-body tr > th, body .leave-registration .ant-table-wrapper .ant-table .ant-table-body tr > td, body .leave-registration .ant-table-wrapper .ant-table .ant-table-body .ant-table-col-state {
  word-break: break-word;
}
body .leave-registration .gtm-toggle-leave-view {
  margin-bottom: 1em;
}

