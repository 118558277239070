.slack-integration {
    .title-menu {
        float: none;
        margin: 3em 0;
    }

    .slack-integration-subtitle {
        font-size: medium;
        width: 80%;
        margin-bottom: 3em;
    }

    .slack-button-row {
        display: flex;
        justify-content: center;
        align-items: flex-end;
    }

    .slack-language {
        display: flex;
        align-items: center;
    }

    .slack-integration-text-wrapper {
        margin: 2em 0;
        width: 60%;

        p {
            font-size: medium;

        }
    }

    .slack-integration-text:last-child {
        margin-bottom: 6em;
    }

    .slack-channel-row {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .slack-channel-row-delete {
            height: 35px;
            margin-right: .5em;
        }

        .slack-channel-row-checkboxes {
            display: flex;
            justify-content: center;
            align-items: center;
        }


        .slack-channel-box {
            margin: auto .1em;
            padding: 0 .4em;

            display: flex; // Add flex to center the checkbox
            justify-content: center; // Center horizontally
            align-items: center; // Center vertically
        }
    }

}