.time-registration .ant-card {
  margin-top: 2em;
}
.time-registration .h1 {
  margin-bottom: 10px;
}
.time-registration .h1 .ant-select {
  width: 200px;
  margin-left: 20px;
}
.time-registration .h1 + p {
  font-size: 14px;
}
.time-registration .h3 {
  line-height: inherit;
}
.time-registration .h3 > .user-image {
  float: left;
  margin: 3px 18px 0 0;
}
.time-registration .ant-table .ant-table-row {
  cursor: pointer;
}
.time-registration .ant-table .ant-table-row td > a {
  display: inline-block;
  padding: 5px 0;
  color: var(--sally-orange);
}
.time-registration .ant-table .ant-table-row .time-registration-hour-line {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 200px;
}
.time-registration .ant-table .ant-table-row .time-registration-cell-loading {
  font-style: italic;
}
.time-registration .gtm-import-timereg {
  margin-bottom: 1.5em;
}
.time-registration .gtm-toggle-time-view {
  margin-bottom: 1.5em;
  width: 147.05 px;
}

.time-registration-import a + .ant-btn,
.time-registration-import .ant-btn + .ant-btn {
  margin-left: 10px;
  padding-left: 20px;
  font-size: 14px;
}
.time-registration-import .ant-upload.ant-upload-drag {
  display: inline-block;
  width: inherit;
}
.time-registration-import .preview-table {
  font-size: 14px;
}
.time-registration-import .preview-table .ant-row {
  margin-bottom: 10px;
}
.time-registration-import .preview-table .ant-row > div + div {
  text-align: right;
}

