.company-dashboards {
    max-width: 1840px;
        margin: auto;
    .title-menu {
        a, span, .ant-btn {
            & + * {
                margin-left: 20px;
            }
        }
        .ant-input-search.ant-input-affix-wrapper {
            width: inherit;

            .ant-input {
                width: 200px;
            }
        }
        a + a .ant-btn {
            padding-left: 20px;
            font-size: 14px;
        }
    }
    .ant-table {
        .ant-table-thead > tr > th, .ant-table-tbody > tr > td {
            padding-right: 10px;
            padding-left: 10px;

            &:first-child {
                padding-left: 30px;
            }
            &:last-child {
                padding-right: 30px;
            }
        }
        .ant-table-row {
            td > div {
                display: block;
                line-height: 26px;
                font-weight: 400;

                a {
                    color: var(--sally-link);
                }
                strong {
                    font-weight: 600;
                }
            }
            td.company-dashboards-info {
                width: 20%;
            }
        }
    }
    .dashboard-calendar-inner {
        border-top: none !important;

        .dashboard-calendar-row {
            & > * {
                &.dashboard-calendar-col1 {
                    width: 55% !important;
                }
                &.dashboard-calendar-col2 {
                    width: 22% !important;
                }
                &.dashboard-calendar-col3 {
                    width: 23% !important;
                }
            }
        }
    }
}
