.slack-integration .title-menu {
  float: none;
  margin: 3em 0;
}
.slack-integration .slack-integration-subtitle {
  font-size: medium;
  width: 80%;
  margin-bottom: 3em;
}
.slack-integration .slack-button-row {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.slack-integration .slack-language {
  display: flex;
  align-items: center;
}
.slack-integration .slack-integration-text-wrapper {
  margin: 2em 0;
  width: 60%;
}
.slack-integration .slack-integration-text-wrapper p {
  font-size: medium;
}
.slack-integration .slack-integration-text:last-child {
  margin-bottom: 6em;
}
.slack-integration .slack-channel-row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.slack-integration .slack-channel-row .slack-channel-row-delete {
  height: 35px;
  margin-right: 0.5em;
}
.slack-integration .slack-channel-row .slack-channel-row-checkboxes {
  display: flex;
  justify-content: center;
  align-items: center;
}
.slack-integration .slack-channel-row .slack-channel-box {
  margin: auto 0.1em;
  padding: 0 0.4em;
  display: flex;
  justify-content: center;
  align-items: center;
}

