.company-users {
    max-width: 1240px;
    margin: auto;
    .h3 {
        line-height: inherit;

        > .user-image {
            float: left;
            margin: 3px 18px 0 0;
        }
    }
    .ant-table .ant-table-row td > a {
        color: var(--sally-link);
    }
    .ant-table .ant-table-col-action {
        width: 1px;

        .icon-trash {
            display: inline-block;
            width: 16px;
            height: 16px;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: contain;
            cursor: pointer;

           
        }
    }
}

.company-users-add {
    padding: 25px 36px 35px 36px;

    .h2 {
        .icon {
            margin: 0 9px -3px 0;
        }
    }
    > p {
        margin: 5px auto 20px auto;
        line-height: 22px;
        font-size: 14px;
        color: var(--sally-grey-shaded);
    }
}

.company-user-permissions {
    .ant-switch-wrapper {
        .ant-switch {
            float: none;
            margin-right: 5px;
        }
    }
}

body .ant-form .ant-department-col {
    .ant-department-col-department {
        border: 1px #ddd solid;
        border-radius: 2px;
        margin: 2px -5px;
        padding: 8px 0;

        .ant-checkbox-wrapper {
            .ant-checkbox {
                float: none;
                margin-right: 5px;
            }
        }
    }

    .ant-department-col-permissions {
        margin-top: 5px;

        .ant-checkbox-wrapper {
            margin: 0;
            padding: 0;
        }
    }
}