body .leave-registration {
        .ant-card{
            margin-top: 2em;
        }
    .h1 {
        margin-bottom: 10px;

        & + p {
            font-size: 14px;
        }
    }
    .h3 {
        line-height: inherit;

        > .user-image {
            float: left;
            margin: 3px 18px 10px 0;
        }
    }
    .ant-table-wrapper .ant-table .ant-table-body {
        .ant-table-row {
            cursor: pointer;

            td > div > a {
                white-space: nowrap;
            }
        }
        tr > th {
            white-space: nowrap;
        }
        tr > th, tr > td, .ant-table-col-state {
            word-break: break-word;
        }
    }
    .gtm-toggle-leave-view{
        margin-bottom: 1em;
    }
}

