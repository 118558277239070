.pay-rolls .pay-rolls-table .period-view strong {
  display: inline-block;
  min-width: 100px;
}
.pay-rolls .pay-rolls-table .period-view strong em {
  font-style: normal;
  color: var(--sally-table-secondary-text);
}
.pay-rolls .pay-rolls-table .period-view strong:first-child {
  padding-right: 15px;
}
.pay-rolls .pay-rolls-table .period-view strong:last-child {
  padding-left: 15px;
}
.pay-rolls .pay-rolls-table .period-view .icon {
  vertical-align: top;
}
.pay-rolls .pay-rolls-table .ant-table-row td:first-child .icon {
  float: left;
}
.pay-rolls .pay-rolls-table .ant-table-row td:first-child .icon + span {
  display: inline-block;
  margin-left: 20px;
}
.pay-rolls .pay-rolls-table .ant-table-row.pay-rolls-table-row-success td:first-child {
  color: var(--sally-status-success-text-on-white);
}
.pay-rolls .pay-rolls-table .ant-table-row.pay-rolls-table-row-success td:first-child .icon-fill {
  fill: var(--sally-status-success-text-on-white);
}
.pay-rolls .pay-rolls-table .ant-table-row.pay-rolls-table-row-waiting td:first-child {
  color: var(--sally-status-awaiting-system-text-on-white);
}
.pay-rolls .pay-rolls-table .ant-table-row.pay-rolls-table-row-waiting td:first-child .icon-fill {
  fill: var(--sally-status-awaiting-system-text-on-white);
}
.pay-rolls .pay-rolls-table .ant-table-row.pay-rolls-table-row-warning td:first-child {
  color: var(--sally-status-awaiting-user-text-on-white);
}
.pay-rolls .pay-rolls-table .ant-table-row.pay-rolls-table-row-warning td:first-child .icon-fill {
  fill: var(--sally-status-awaiting-user-text-on-white);
}
.pay-rolls .pay-rolls-table .ant-table-row.pay-rolls-table-row-error td:first-child {
  color: var(--sally-status-failure-text-on-white);
}
.pay-rolls .pay-rolls-table .ant-table-row.pay-rolls-table-row-error td:first-child .icon-fill {
  fill: var(--sally-status-failure-text-on-white);
}
.pay-rolls .pay-rolls-table .ant-table-row.pay-rolls-table-row-clickable {
  cursor: pointer;
}
.pay-rolls .h2 + .pay-rolls-table {
  margin-top: 15px;
}
.pay-rolls .pay-rolls-help-text {
  margin: -20px 0 30px 0;
}

.ant-card.ant-card-bordered.no-pay-roll-card .ant-card-body {
  background-position: 30px center;
  background-repeat: no-repeat;
  background-size: 40px 40px;
}
.ant-card.ant-card-bordered.no-pay-roll-card .ant-card-body p {
  margin: 0;
  font-size: 14px;
}
.ant-card.ant-card-bordered.no-pay-roll-card .ant-card-body a {
  position: absolute;
  top: 50%;
  right: 40px;
  display: inline-block;
  line-height: 20px;
  margin-top: -10px;
  padding-right: 15px;
  background-position: right center;
  background-repeat: no-repeat;
  background-size: 12px 12px;
  font-size: 14px;
  font-weight: 500;
}
.ant-card.ant-card-bordered.no-pay-roll-card .ant-card-body, .ant-card.ant-card-bordered.no-pay-roll-card:hover .ant-card-body {
  padding: 25px 110px 25px 90px;
}

.pay-roll-create {
  width: 65%;
  margin: auto;
}

.previous-payrolls-header {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  justify-content: space-between;
}

.payrolls-select-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.payrolls-select-container .payrolls-select {
  width: 10rem;
}
.payrolls-select-container .payroll-select-label {
  margin-bottom: 0.1em;
  padding-right: 0.5em;
}

